import React, { useRef, useEffect }  from "react";
import "./searchbox.css";
import ColorLogo from "../images/cloto-sm.png";

/**
 * A reusable ZIR search box component.
 * 
 * @param {query} The query text.
 * @param {setQueryFn} A function that will be invoked to set the query text.
 * @param {submitFn} A function that is invoked when the user initiates
 *         the search.
 */
function ZirSearchBox({
    query,
    setQueryFn,
    submitFn,
    searchRunning,
    setSearchRunningFn}) {

  const buttonRef = useRef();
  const inputRef = useRef();

  const startAnimation = () => {
    buttonRef.current.classList.add("loading");
  };

  const stopAnimation = () => {
    buttonRef.current.addEventListener(
      "animationiteration",
      () => {
        buttonRef.current.classList.remove("loading");
      },
      { once: true }
    );
  };

  useEffect(() => {
    if (searchRunning) {
      startAnimation();
    } else {
      stopAnimation();
    }
  }, [searchRunning]); // Only re-run the effect if count changes

  async function wrappedSubmit() {
    setSearchRunningFn(true);
    try {
      await submitFn(inputRef.current.value);
    } finally {
      setSearchRunningFn(false);
    }
  }

  function keyPressFn(e) {
    if (!e) e = window.event;
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      wrappedSubmit();
    }
  }

  return (
    <div className="zirContainer w-full">
    <div className="zirSearch mr-6">
      <input
        onKeyPress={(e) => keyPressFn(e)}
        onChange={(e) => setQueryFn(e.target.value)}
        placeholder="Ask your question here."
        value={query}
        ref={inputRef}
      />
      <button alt="Search">
        <img
          style={{
           height: '26px'
          }}
          alt="ZIR AI Logo"
          src={ColorLogo}
          onClick={(e) => wrappedSubmit(e)}
          ref={buttonRef}
        ></img>
      </button>
    </div>
  </div>
  );
}

export default ZirSearchBox;
