import React, { useState } from "react";
import { Helmet } from "react-helmet";

import ZirSearchBox from "../components/searchbox";

import Header from "../templates/header";
import Footer from "../templates/footer";

// markup
const PlansAndPricing = () => {
  const faqs = {}
  faqs["How does Cloto work?"] = (
    <span itemprop="text">
    Subscribe to a monthly plan or schedule a pickup at pay-per-use rates.
    Select your pickup date and the number of Clo-Totes you need washed or
    items you need dry cleaned. Confirm the order and place the Clo-Totes or
    Clo-Bags on your doorstep. Don’t worry, we’ll drop your starter Clo-Totes or
    Clo-Bags before your first pickup. Done, quick and simple.
    </span>
  );
  faqs["When do I get my clothes back?"] = (
    <span itemprop="text">
    For residential customers, we have a 2 day delivery guarantee for wash
    orders and 3 day delivery for dry clean orders (barring exceptional
    circumstances). Don’t keep looking out the window, you can track your order
    in the app. Turn on Cloto app notifications for pickup and delivery
    notifications.
    </span>
  );
  faqs["What areas do you serve?"] = (
    <span itemprop="text">
    We currently serve the following cities in the Greater Toronto Area:
    Scarborough, Markham, Pickering, Ajax, Whitby, Brooklin, Oshawa, Courtice,
    Hampton, Bowmanville and Port Perry. Download the Cloto app or enter your
    address on our homepage for a service area check.
    </span>
  );
  faqs["What services does Cloto provide?"] = (
    <span itemprop="text">
    We provide door-to-door wash, dry, and fold service. We also provide dry
    cleaning and commercial laundry service.
    </span>
  );
  faqs["What items do you accept for wash orders?"] = (
    <span itemprop="text">
    We accept any items normally washed at home plus large items like
    comforters and blankets. We also accept pillows, duvets, duvet covers, and
    non-rubber backing bath mats. Please do not send items requiring special
    care like cashmere sweaters and fine silks. Such items may be damaged in a
    wash and fold service. Instead, use our dry cleaning service for specialty
    items.
    </span>
  );
  faqs["Do I need to be home?"] = (
    <span itemprop="text">
    Nope. Set it and forget it at your doorstep for pickup. Clo-Totes and Clo-
    Bags will be delivered back at your doorstep. You can track your order by
    clicking on the order card in the Cloto app. Turn on Cloto app notifications for
    pickup and delivery notifications.
    </span>
  );
  faqs["What do I put my clothes in?"] = (
    <span itemprop="text">
    Cloto provides starter Clo-Totes and Clo-Bags to all our subscribers for free
    (additional Clo-Totes may be purchased). Pay-per-use customers will need to
    purchase Clo-Totes (one-time fee) through the Cloto app before scheduling
    their wash orders. Place your items in the Clo-Tote or Clo-Bag and let Cloto
    handle the rest.
    </span>
  );
  faqs["What is Cloto’s standard for washing?"] = (
    <span itemprop="text">
    We strive to wash and fold your clothes like mom does :) Please keep in mind
    we are a mass laundry service and cannot accommodate special care
    instructions, i.e. pre-treatment, hang dry, or delicate cycles for individual
    items. You can spot treat any stubborn stains before dropping the item in
    your tote.
    </span>
  );
  faqs["Can I select wash preferences?"] = (
    <span itemprop="text">
    At this time, we don’t offer selection of preferences. Our staff is trained to
    select the most appropriate cycle for your wash loads based on the type of
    garments received. If you have any questions, feel free to contact{" "}
      <a
        href="mailto:customercare@cloto.ca"
        className="underline text-indigo-500"
      >
        customercare@cloto.ca
      </a>
      .
    </span>
  );
  faqs["Do you provide dry cleaning services?"] = (
    <span itemprop="text">
    Yes! Visit our <a href="/plans-and-pricing/#dry-cleaning" className="text-indigo-500 underline">plans and pricing page</a>{" "}
    to see a list of the items we accept for dry cleaning.
    </span>
  );

  faqs["Do you provide dry cleaning service?"] = faqs["Do you provide dry cleaning services?"]
  faqs["Where does my tote go?"] = (
    <span itemprop="text">
    Your Clo-Totes go through an in-house wash process, only handled by our
    trained Clo-Techs (staff) to ensure quality and care.
    </span>
  );
  faqs["How am I billed?"] = (
    <span itemprop="text">
    You are billed every 4 weeks for monthly subscription plans. Pay-per-use
    orders and wash overages are charged when your order is picked up. Dry 
    clean orders are charged once items are picked up and inventoried.
    </span>
  );
  faqs["When will I be billed?"] = (
    <span itemprop="text">
    Your credit card will be charged when you subscribe to a monthly plan and
    on subsequent renewal dates (every 4 weeks). 
    </span>
  );
  faqs["How do I pay?"] = (
    <span itemprop="text">
    Using Stripe as our secure payment processor, we accept all
    major credit cards. Just add your card during signup or update
    it later under “Plans &amp; Pricing” menu of the app.
    </span>
  );
  faqs["My credit card expired, how do I update it?"] = (
    <span itemprop="text">
    You can add a new card under the “Plans &amp; Pricing” menu of
    the Cloto app and select it as your default payment method.
    </span>
  );
  faqs["How do I change my service plan?"] = (
    <span itemprop="text">
    You can upgrade, downgrade, or cancel your subscription plan at
    any time in the Cloto app or by contacting{" "}
    <a
      href="mailto:customercare@cloto.ca"
      className="underline text-indigo-500"
    >
      customercare@cloto.ca
    </a>.
    Plan upgrades midcycle will take effect immediately and
    downgrades will take effect from your next monthly cycle.
    </span>
  );
  faqs["I forgot something in my Clo-Tote!"] = (
    <span itemprop="text">
    Our Clo-techs are trained to spot (large) items not intended for wash. We’ll
    place these items back in your tote. However, we cannot guarantee item
    retrieval nor are we liable for damage to garments caused by small items like
    gum, slime, or candy left behind in pockets or in the tote. Please remove
    items not intended for wash as they can also damage our equipment. Cloto
    reserves the right to end service for repeat offenders or if dangerous items
    are found.
    </span>
  );
  faqs["What if I miss my pickup?"] = (
    <span itemprop="text">
    No problem, we won’t charge you for a missed pickup attempt if you’re on a
    subscription plan. Just schedule a pickup for the following pickup day for
    your area. Please note, unused tote washes from your monthly wash allowances 
    do not carry over into new subscription/billing cycles. Remember, you can 
    schedule orders up to 4 weeks in advance. Don't forget to turn on Cloto app 
    notifications for reminders.
    </span>
  );
  faqs["Can I schedule an order at a different address?"] = (
    <span itemprop="text">
    If you have moved, no problem. You can change your address in
    the profile menu of the Cloto app.
    </span>
  );
  faqs["Should I be worried about my privacy?"] = (
    <span itemprop="text">
    We take your privacy very seriously. Your Clo-Totes and Clo-Bags
    are anonymized and tracked via QR code technology so that no one,
    including our Clo-Techs (fancy word for laundry staff), know the
    source of the CloTotes and Clo-Bags. Your items are never washed
    with anyone else’s and your Clo-Totes are always disinfected.
    </span>
  );
  faqs["What detergent do you use?"] = (
    <span itemprop="text">
    We use our own in-house eco-friendly solutions to wash and dry your
    clothes. Our unscented detergent is free of sulfates, phosphates and optical
    brighteners. You get clean clothes without the dirty ingredients.
    </span>
  );
  faqs["What if I’m missing a garment?"] = (
    <span itemprop="text">
    We employ QR code technology to ensure your Clo-Totes and
    Clo-Bags, and the items within, never get lost or mixed with
    anyone else’s. If you feel something is missing, feel free to
    contact customer support at{" "}
    <a
      href="mailto:customercare@cloto.ca"
      className="underline text-indigo-500"
    >
      customercare@cloto.ca
    </a>{" "}
    and we’d be happy to address your concerns.
    </span>
  );

  const [query, setQuery] = useState("");
  const [searching, setSearching] = useState(false);
  const [searched, setSearched] = useState(false);
  const [resultTitle, setResultTitle] = useState("");
  const [resultBody, setResultBody] = useState("");
  
  let answerClass = "mt-6 hidden";
  if (query !== "" && searched) {
    answerClass = "mt-6";
  }

  async function search(query) {
    if (query === "") {
      
    }
    fetch("https://faq.zir.site/cloto/query", {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: encode({
        q: query,
        n: 1,
      }),
    })
      .then(async function (response) {
        let result = await response.json();
        if (result.docs.length > 0) {
          let doc = result.docs[0];
          let title = doc.title.trim();
          setResultTitle(title);

          let section = doc.sections[0];
          let snippet = section.snippets.length === 0 ? "" : section.snippets[0].text;
          if (title in faqs) {
            setResultBody(faqs[title]);
          } else {
            setResultBody(<span>Missing body text.</span>);
          }
        }
        setSearched(true);
        console.log(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div>
      <Helmet>
        <html itemScope itemtype="https://schema.org/FAQPage"></html>
        <title>Cloto Frequently Asked Questions</title>
        <meta name="description" content="Answers to the most commonly asked questions about Cloto's subscription laundry service."/>
      </Helmet>
      <Header selected={3}></Header>

      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-gray-900">
            Frequently asked questions
          </h2>
          <div className="w-full md:w-3/4 mt-6">
            <ZirSearchBox
              query={query}
              setQueryFn={setQuery}
              submitFn={search}
              searchRunning={searching}
              setSearchRunningFn={setSearching}
            />
            <div className={answerClass}>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {resultTitle}
                </h3>
                <div className="mt-2 text-base text-gray-500 w-full md:w-3/4">{resultBody}</div>
                <div className="mt-4 text-base text-gray-700">
                  <b>Didn't answer your question?</b> Browse our full set of FAQs below.
                </div>
            </div>
          </div>
          <div className="mt-6 pt-10 border-t-2 border-gray-200">
            <h2 className="mb-6 text-2xl font-semibold">Your first order</h2>
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  How does Cloto work?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["How does Cloto work?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  When do I get my clothes back?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["When do I get my clothes back?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  What areas do you serve?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["What areas do you serve?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  What services does Cloto provide?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["What services does Cloto provide?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  What items do you accept for wash orders?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["What items do you accept for wash orders?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  Do I need to be home?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["Do I need to be home?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  What do I put my clothes in?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["What do I put my clothes in?"]}
                </dd>
              </div>
            </dl>

            <h2 className="mb-6 mt-20 text-2xl font-semibold">Cleaning</h2>
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  What is Cloto’s standard for washing?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["What is Cloto’s standard for washing?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  Can I select wash preferences?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["Can I select wash preferences?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  Do you provide dry cleaning services?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["Do you provide dry cleaning services?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  Where does my tote go?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["Where does my tote go?"]}
                </dd>
              </div>
            </dl>

            <h2 className="mb-6 mt-20 text-2xl font-semibold">
              Billing
            </h2>
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  How am I billed?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["How am I billed?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  When will I be billed?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["When will I be billed?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  How do I pay?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["How do I pay?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  My credit card expired, how do I update it?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["My credit card expired, how do I update it?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  How do I change my service plan?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["How do I change my service plan?"]}
                </dd>
              </div>
            </dl>

            <h2 className="mb-6 mt-20 text-2xl font-semibold">
              Orders and concerns
            </h2>
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  I forgot something in my Clo-Tote!
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["I forgot something in my Clo-Tote!"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  What if I miss my pickup?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["What if I miss my pickup?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  Can I schedule an order at a different address?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["Can I schedule an order at a different address?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  Should I be worried about my privacy?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["Should I be worried about my privacy?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  What detergent do you use?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["What detergent do you use?"]}
                </dd>
              </div>

              <div itemScope itemprop="mainEntity" itemtype="https://schema.org/Question">
                <dt itemprop="name" className="text-lg leading-6 font-medium text-gray-900">
                  What if I’m missing a garment?
                </dt>
                <dd className="mt-2 text-base text-gray-500" itemScope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                  {faqs["What if I’m missing a garment?"]}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

/**
 * https://stackoverflow.com/questions/35325370/post-a-x-www-form-urlencoded-request-from-react-native
 */
 function encode(details) {
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return formBody;
}

export default PlansAndPricing;
